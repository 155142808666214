import React, { useState } from 'react';
import './ContactPage.css';

function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill in all fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('http://localhost:3001/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('메시지가 전송되었습니다!\nMessage sent successfully!');

        // Clear the form after successful submission
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        alert('메시지 전송에 실패했습니다. 나중에 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('서버와의 통신 중 오류가 발생했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-wrapper">
        <div className="contact-info">
          <h2>플로리다 말씀교회</h2>
          <p>7509 Van Dyke Rd, Odessa, FL 33556</p>
          <p><strong>Phone:</strong> (213)-804-9674</p>
          <p>Email: gimoseskim68@gmail.com</p>

          <div className="contact-form">
            <h3>Contact Us</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your name"
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email"
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your message"
                  disabled={isSubmitting}
                ></textarea>
              </div>

              <button type="submit" className="submit-button" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>

        <div className="map-container">
        <iframe 
        title="Church Location Map" // Add this line
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.1503542220826!2d-82.56899732429734!3d28.12925747594325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c295639085f7ab%3A0xf1c4d2e1b5f3dc8b!2s7509%20Van%20Dyke%20Rd%2C%20Odessa%2C%20FL%2033556!5e1!3m2!1sen!2sus!4v1723863142896!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: 0, width: "100%", height: "100%" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        </div>
      </div>
    </div>
  );
}

export default ContactPage;

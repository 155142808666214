import React from 'react';
import './PhotoAlbum.css';

// Import your images
import photo1 from './photo1.jpg';
import photo2 from './photo2.JPG';
import photo3 from './photo3.jpg';
import photo4 from './photo4.jpg';
import photo5 from './photo5.JPG';
import photo6 from './photo6.jpg';

function PhotoAlbum() {
  const photos = [
    { src: photo1, title: '믿음 구역 모임'},
    { src: photo2, title: '이사 감사예배'},
    { src: photo3, title: '허난도 구역 모임' },
    { src: photo4, title: '믿음 구역 모임' },
    {src: photo5, title: '김진훈집사님 가정'},
    {src: photo6, title: '꽃 잔치'}
  ];

  return (
    <div className="photo-album">
      <div className="album-title">
        <h1 className="photo-album-title">포토앨범</h1>
        <p className="photo-album-subtitle">PHOTO ALBUM</p>
      </div>
      <div className="photo-grid">
        {photos.map((photo, index) => (
          <div key={index} className="photo-item">
            <img src={photo.src} alt={photo.title} />
            <h3>{photo.title}</h3>
            <p>{photo.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoAlbum;

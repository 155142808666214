import React, { useState } from 'react';
import './Header.css';
import logo from './FCW.png'; // Import your logo
import { Link, useNavigate } from 'react-router-dom';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu toggle
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.location.reload(); // Force the page to reload
  };

  // Toggle menu for mobile devices
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close the menu after navigation
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header-container">
      <div className="header-content">
        <div className="header-left" onClick={handleClick} style={{ cursor: 'pointer' }}>
          <img
            src={logo}
            alt="Church Logo"
            className="church-logo"
          />
          <div className="church-name">
            <h1>플로리다 말씀교회</h1>
            <h2>Florida Church of the Word</h2>
          </div>
        </div>
        
        {/* Hamburger icon */}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        
        
        {/* Navigation links */}
        <nav className={`header-nav ${menuOpen ? 'mobile open' : 'desktop'}`}>
          <ul>
            <li><Link to="/Church-Introduction" onClick={closeMenu}>교회소개</Link></li>
            <li><Link to="/Worship" onClick={closeMenu}>예배</Link></li>
            <li><Link to="/education" onClick={closeMenu}>교육</Link></li>
            <li><Link to="/photo-gallery" onClick={closeMenu}>포토 갤러리</Link></li>
            <li><Link to="/missions" onClick={closeMenu}>목회서신</Link></li>
            <li><Link to="/news" onClick={closeMenu}>소식</Link></li>
            <li><Link to="/online-worship" className="highlighted" onClick={closeMenu}>온라인 예배</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';
import './Footer.css';  // Optional: Add CSS if needed for styling

function Footer() {
  return (
    <footer className="Footer">
      <p>COPYRIGHT © 2024 플로리다 말씀교회 FLORIDA CHURCH OF THE WORD ALL RIGHTS RESERVED </p>
    </footer>
  );
}

export default Footer;

import React from 'react';
import './PhotoGallery.css'; // Assuming you want to style the gallery using PhotoGallery.css

import food2 from './food2.JPG'; // Correctly importing the images
import group from './group.JPG';
import group2 from './group2.JPG';
import group3 from './group3.JPG'; // Importing video
import group4 from './group4.JPG'

const Group = () => {
  const foodImages = [
    { type: 'image', src: food2}, // Image
    { type: 'image', src: group}, // Image
    { type: 'image', src: group2,},   // Image
    { type: 'image', src: group3,}, //Image
    { type: 'image', src: group4,}, //Image
  ];

  return (
    <div className="event-gallery-container">
      <div className="gallery-grid">
        {foodImages.map((media, index) => (
          <div key={index} className="gallery-item">
            {media.type === 'image' ? (
              <img src={media.src} alt={media.title} />
            ) : (
              <video width="320" height="240" controls>
                <source src={media.src} type="video/mp4" />
              </video>
            )}
            <p>{media.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Group;

import { useEffect } from 'react';

function OnlineWorshipPage() {
  useEffect(() => {
    // Redirect to YouTube URL
    window.location.href = 'https://www.youtube.com/@giskim3732';
  }, []);

  // Return null as there's no need to render anything
  return null;
}

export default OnlineWorshipPage;

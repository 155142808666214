import React from 'react';
import './PhotoGallery.css'; // Assuming you want to style the gallery using PhotoGallery.css

import gathering2 from './gathering2.JPG'; // Correctly importing the images
import gathering3 from './gathering3.JPG';
import birthday from './birthday.JPG';
import birthday2 from './birthday2.MP4'; // Importing video

const Gathering = () => {
  const thanksgivingImages = [
    { type: 'image', src: gathering2}, // Image
    { type: 'image', src: gathering3}, // Image
    { type: 'image', src: birthday,},   // Image
    { type: 'video', src: birthday2,}, // Video
  ];

  return (
    <div className="event-gallery-container">
      <div className="gallery-grid">
        {thanksgivingImages.map((media, index) => (
          <div key={index} className="gallery-item">
            {media.type === 'image' ? (
              <img src={media.src} alt={media.title} />
            ) : (
              <video width="320" height="240" controls>
                <source src={media.src} type="video/mp4" />
              </video>
            )}
            <p>{media.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gathering;

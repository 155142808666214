import React from 'react';
import './EducationPage.css'; // Ensure to link your CSS file

import youth from './youth.jpg'; // Import your youth image
import youthgroup1 from './youthgroup1.JPG';
import youthgroup2 from './youthgroup2.JPG';

function AboutPage() {
  return (
    <div className="youth-page">
      <div className="header-image-container">
        <img src={youth} alt="Youth Group" className="header-image" />
        <div className="header-text">
          <h1>중고등부 모임</h1>
          <p>Youth Group</p>
        </div>
      </div>

      {/* Information above the images */}
      <div className="info-section">
        <h2>주일학교 11:00 AM</h2>
        <h2>Youth Group 11:00 AM</h2>
      </div>

      {/* Youth Group Images */}
      <div className="youth-images">
        <img src={youthgroup1} alt="Youth Group 1" className="youth-image" />
        <img src={youthgroup2} alt="Youth Group 2" className="youth-image" />
      </div>
    </div>
  );
}

export default AboutPage;

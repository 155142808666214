import React from 'react';
import './App.css';
import Header from './Header';
import SlideShow from './SlideShow';
import PhotoAlbum from './PhotoAlbum';
import YouTubePage from './YouTubePage';
import ContactPage from './ContactPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Footer';

// Importing the pages
import ChurchIntroduction from './pages/ChurchIntroduction';
import AboutPage from './pages/AboutPage';
import EducationPage from './pages/EducationPage';
import MissionsPage from './pages/MissionsPage';
import NewsPage from './pages/NewsPage';
import OnlineWorshipPage from './pages/OnlineWorshipPage';
import NewsDetail from './pages/NewsDetail'; // Correctly referencing NewsDetail
import PhotoGallery from './pages/PhotoGallery';
import Gathering from './pages/Gathering';
import Group from './pages/Group';
import Greetings from './pages/Greetings';
import Smile from './pages/Smile';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="content">
          <Routes>
            {/* Home Page Route with SlideShow, PhotoAlbum, YouTubePage, and ContactPage */}
            <Route
              path="/"
              element={
                <>
                  <SlideShow />
                  <YouTubePage />
                  <PhotoAlbum />
                  <ContactPage />
                </>
              }
            />

            {/* Other routes for different pages */}
            <Route path="/Church-Introduction" element={<ChurchIntroduction />} />
            <Route path="/Worship" element={<AboutPage />} />
            <Route path="/education" element={<EducationPage />} />
            <Route path="/Photo-Gallery" element={<PhotoGallery />} /> 
            <Route path="/missions" element={<MissionsPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/online-worship" element={<OnlineWorshipPage />} />
            <Route path="/gathering" element={<Gathering />} /> {/* Add this route for Gathering */}
            <Route path="/Fellowship" element={<Group />} /> {/* Add this route for Gathering */}
            <Route path="/Greetings" element={<Greetings />} /> {/* Add this route for Greetings */}
            <Route path="/Smile" element={<Smile />} /> {/* Add this route for Smile */}


            {/* Route for individual announcement details */}
            <Route path="/announcement/:id" element={<NewsDetail />} /> {/* Using NewsDetail route */}
          </Routes>
        </main>
        <Footer /> {/* Footer is now outside of the main content to appear on every page */}
      </div>
    </Router>
  );
}

export default App;

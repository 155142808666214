import React from 'react';
import './PhotoGallery.css'; // Assuming you want to style the gallery using PhotoGallery.css

import smile from './smile.JPG'; // Correctly importing the images
import smile2 from './smile2.JPG';
import smile3 from './smile3.JPG';
import smile4 from './smile4.JPG'; 

const Gathering = () => {
  const thanksgivingImages = [
    { type: 'image', src: smile}, 
    { type: 'image', src: smile2}, 
    { type: 'image', src: smile3,},   
    { type: 'image', src: smile4,}, 
  ];

  return (
    <div className="event-gallery-container">
      <div className="gallery-grid">
        {thanksgivingImages.map((media, index) => (
          <div key={index} className="gallery-item">
            {media.type === 'image' ? (
              <img src={media.src} alt={media.title} />
            ) : (
              <video width="320" height="240" controls>
                <source src={media.src} type="video/mp4" />
              </video>
            )}
            <p>{media.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gathering;

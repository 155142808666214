import React from 'react';
import './PhotoGallery.css'; // Assuming you want to style the gallery using PhotoGallery.css

import greetings from './greetings.JPG'; // Correctly importing the images
import greetings2 from './greetings2.JPG';
import greetings3 from './greetings3.JPG';
import greetings4 from './greetings4.JPG'; 

const Gathering = () => {
  const thanksgivingImages = [
    { type: 'image', src: greetings}, 
    { type: 'image', src: greetings2}, 
    { type: 'image', src: greetings3,},   
    { type: 'image', src: greetings4,}, 
  ];

  return (
    <div className="event-gallery-container">
      <div className="gallery-grid">
        {thanksgivingImages.map((media, index) => (
          <div key={index} className="gallery-item">
            {media.type === 'image' ? (
              <img src={media.src} alt={media.title} />
            ) : (
              <video width="320" height="240" controls>
                <source src={media.src} type="video/mp4" />
              </video>
            )}
            <p>{media.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gathering;

import React, { useState } from 'react';
import './NewsPage.css'; // Link to the CSS
import candles from './candles.jpg'; // Import the candles image

const announcements = [
  { id: 1, date: '2024-06-02', title: '소식' },
  { id: 2, date: '2024-06-09', title: '소식' },
  { id: 3, date: '2024-06-16', title: '소식' },
  { id: 4, date: '2024-06-23', title: '소식' },
  { id: 5, date: '2024-06-30', title: '소식' },
  { id: 6, date: '2024-07-07', title: '소식' },
  { id: 7, date: '2024-07-14', title: '소식' },
  { id: 8, date: '2024-07-21', title: '소식' },
  { id: 9, date: '2024-07-28', title: '소식' },
  { id: 10, date: '2024-08-04', title: '소식' },
  { id: 11, date: '2024-08-11', title: '소식' },
  { id: 12, date: '2024-08-18', title: '소식' },
  { id: 13, date: '2024-08-25', title: '소식' },
  { id: 14, date: '2024-09-01', title: '소식' },
  { id: 15, date: '2024-09-08', title: '소식' },
  { id: 16, date: '2024-09-15', title: '소식' },
  { id: 17, date: '2024-09-22', title: '소식' },
  { id: 18, date: '2024-09-29', title: '소식' },
  { id: 19, date: '2024-10-06', title: '소식' },
  { id: 20, date: '2024-10-13', title: '소식' },
  { id: 21, date: '2024-10-20', title: '소식' },
  { id: 22, date: '2024-10-27', title: '소식' },
  { id: 23, date: '2024-11-3', title: '소식' },
  { id: 24, date: '2024-11-10', title: '소식' },
  { id: 25, date: '2024-11-17', title: '소식' },
  { id: 26, date: '2024-11-24', title: '소식' },
  { id: 27, date: '2024-12-1', title: '소식' },

];

// Reverse the announcements array
const reversedAnnouncements = [...announcements].reverse(); // Creates a new reversed array

// Number of announcements per page
const announcementsPerPage = 7;

function NewsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  // Total number of pages
  const totalPages = Math.ceil(reversedAnnouncements.length / announcementsPerPage);

  // Determine the announcements to display on the current page
  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement = indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = reversedAnnouncements.slice(indexOfFirstAnnouncement, indexOfLastAnnouncement);

  // Handle first, previous, next, and last page navigation
  const goToFirstPage = () => setCurrentPage(1);
  const goToPreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  const goToLastPage = () => setCurrentPage(totalPages);

  const goToPage = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="news-page">
      <div className="header-image-container">
        <img src={candles} alt="Candles" className="header-image" />
        <div className="header-text">
          <h1>교회소식</h1>
          <p>Announcements</p>
        </div>
      </div>

      <table className="news-table">
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>게시일</th>
          </tr>
        </thead>
        <tbody>
          {currentAnnouncements.map((announcement, index) => (
            <tr key={announcement.id}>
              <td>{indexOfFirstAnnouncement + index + 1}</td>
              <td>
                <a href={`/announcement/${announcement.id}`} className="announcement-link">
                  {announcement.title}
                </a>
              </td>
              <td>{announcement.date}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={goToFirstPage} disabled={currentPage === 1}>&laquo;&laquo;</button>
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>&laquo;</button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={index + 1 === currentPage ? 'active' : ''}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>&raquo;</button>
        <button onClick={goToLastPage} disabled={currentPage === totalPages}>&raquo;&raquo;</button>
      </div>
    </div>
  );
}

export default NewsPage;

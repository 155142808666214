import React from 'react';
import './YouTubePage.css';

function YouTubePage() {
  return (
    <div className="youtube-page">
      <h1>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="YouTube"
          className="youtube-logo" 
        />
      </h1>
      <div className="video-grid">
        {/* First Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/q95m6pi2RAo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-우리 손의 날을 견고케 하소서 <br /><span className="eng-text">(12/1/2024)</span>
          </p>
        </div>

        {/* Second Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/ule8ZnXqOeg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-그의 하나님께 감사하였더라 (추수감사주일) <br /><span className="eng-text">(11/24/2024)</span>
          </p>
        </div>

        {/* Third Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/yJVBwIYk_PA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-이스라엘을 계수하게 하니라 <br /><span className="eng-text">(11/17/2024)</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default YouTubePage;

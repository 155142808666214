import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Include autoplay if needed
import './SlideShow.css'; // Your custom styles

// Import required modules from 'swiper/modules'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import your images
import cross from './cross.jpg';
import bible from './bible.jpg';
import praise from './praise.jpg';

function Slideshow() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      loop={true}
    >
      <SwiperSlide>
        <div className="slide" style={{ backgroundImage: `url(${cross})` }}>
          <div className="slide-content">
            <h2>플로리다 말씀교회에 오신것을 환영합니다</h2>
            <p>Welcome to Florida Church of the Word</p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="slide slide-bible" style={{ backgroundImage: `url(${bible})` }}>
          <div className="slide-content-right"> {/* New class for Bible image */}
            <h2>플로리다 말씀교회는</h2>
            <p>  예수님만 사랑하고 자랑합니다</p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="slide" style={{ backgroundImage: `url(${praise})` }}>
          <div className="slide-content">
            <h2> 찬양과 예배가 살아있는 교회 </h2>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Slideshow;